/* manage-users.css */

/* Main container styles */
.manage-users-page {
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 2rem;
}

.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
}

.dashboard-title {
    color: #2c3e50;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
}

/* Navigation and Search */
.back-to-dashboard {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #6c757d;
    font-size: 0.95rem;
    margin-bottom: 2rem;
    transition: color 0.2s ease;
}

.back-to-dashboard:hover {
    color: #343a40;
}

.search-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;
    max-width: 100%;
    position: relative;
}

.search-input {
    flex: 1;
    padding: 0.75rem 1rem;
    padding-right: 3.5rem; /* Tạo khoảng trống cho nút */
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.search-input:focus {
    outline: none;
    border-color: #ae1c3f;
    box-shadow: 0 0 0 3px rgba(174, 28, 63, 0.15);
}

.btn-add-new {
     background-color: #ae1c3f;
    color: white;
    border: none;
    padding: 10px 20px !important;
    border-radius: 3px !important;
    cursor: pointer;
    font-size: 14px !important;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 100px !important;
}

.btn-add-new:hover {
    background-color: #8e1733;
    transform: translateY(-50%) scale(1.02);
}

/* Responsive */
@media (max-width: 768px) {
    .search-container {
        flex-direction: column;
        position: relative;
    }

    .search-input {
        width: 100%;
        padding-right: 1rem;
    }

    .btn-add-new {
        position: static;
        transform: none;
        width: 100%;
        margin-top: 0.5rem;
        padding: 0.75rem;
    }
}

/* Table Styles */
.table-container {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.users-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    white-space: nowrap;
}

.users-table th {
    background-color: #f8fafc;
    color: #4a5568;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 1rem;
    border-bottom: 2px solid #e2e8f0;
}

.users-table td {
    padding: 1rem;
    color: #2d3748;
    font-size: 0.95rem;
    border-bottom: 1px solid #e2e8f0;
    vertical-align: middle;
}

.users-table tbody tr:hover {
    background-color: #f8fafc;
}

/* Table Action Buttons */
.action-buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

.btn-edit,
.btn-delete,
.btn-reset {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.btn-edit {
    background-color: #4299e1;
    color: white;
}

.btn-edit:hover {
    background-color: #3182ce;
}

.btn-delete {
    background-color: #f56565;
    color: white;
}

.btn-delete:hover {
    background-color: #e53e3e;
}

.btn-reset {
    background-color: #48bb78;
    color: white;
}

.btn-reset:hover {
    background-color: #38a169;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background-color: white;
    border-radius: 12px;
    padding: 2rem;
    width: 100%;
    max-width: 480px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.modal h3 {
    color: #2d3748;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

/* Form Styles */
.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    color: #4a5568;
    font-size: 0.95rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
}

.form-group input:focus,
.form-group select:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.form-group small {
    display: block;
    color: #718096;
    font-size: 0.85rem;
    margin-top: 0.5rem;
}

/* Modal Actions */
.modal-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 2rem;
}

.modal-actions button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.btn-primary {
    background-color: #4299e1;
    color: white;
}

.btn-primary:hover {
    background-color: #3182ce;
}

.btn-secondary {
    background-color: #e2e8f0;
    color: #4a5568;
}

.btn-secondary:hover {
    background-color: #cbd5e0;
}

/* Alert Modal */
.alert-modal {
    text-align: center;
    max-width: 400px;
}

.alert-modal p {
    color: #2d3748;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .manage-users-page {
        padding: 1rem;
    }

    .search-container {
        flex-direction: column;
    }

    .search-input,
    .btn-add-new {
        width: 100%;
    }

    .action-buttons {
        flex-wrap: wrap;
    }

    .modal {
        margin: 1rem;
        padding: 1.5rem;
    }
}

/* Status Badge for Admin/User */
.status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.85rem;
    font-weight: 500;
}

.status-badge.admin {
    background-color: #ebf8ff;
    color: #2b6cb0;
}

.status-badge.user {
    background-color: #f7fafc;
    color: #4a5568;
}