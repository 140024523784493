.exam-quiz-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f7fa;
    padding-bottom: 20px; /* Để footer không che nội dung */
}

.exam-header {
    background: #ae1c3f;
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}

.timer {
    font-size: 18px;
    font-weight: 500;
}

.exam-progress {
    font-size: 14px;
}

/* Container nội dung chính */
.exam-container {
    flex: 1;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 50px;
    min-height: calc(100vh - 100px); /* Đảm bảo không có tràn footer */
    padding-bottom: 100px; /* Thêm khoảng cách dưới để tránh bị che */
}

/* Phần câu hỏi */
.question-section {
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.question-header h3 {
    color: #333;
    margin: 0 0 15px 0;
    font-size: 16px;
}

.question-content {
    font-size: 15px;
    line-height: 1.5 !important;
    color: darkred;
    margin-bottom: 15px;
    white-space: normal; /* Đảm bảo nội dung không bị cắt */
    overflow-wrap: break-word; /* Tự động ngắt từ khi không đủ không gian */
    word-break: break-word; /* Ngắt từ nếu quá dài */
    hyphens: auto; /* Tự động thêm dấu gạch ngang nếu cần */
}

.answers-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.answer-option {
	font-size: 15px;
    display: flex;
    align-items: flex-start;
    padding: 12px 15px;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    user-select: none;
}

.answer-option:hover {
    border-color: #ae1c3f;
    background-color: #f8f9fa;
}

.answer-option.selected {
  
    border-color: #ae1c3f;
    color: #b50404;
    font-weight: 500;
}

.answer-option input[type="radio"] {
    margin: 3px 12px 0 0;
    cursor: pointer;
}

.answer-text {
    flex: 1;
    line-height: 1.5;
}

/* Nút điều hướng câu hỏi */
.button-navigation {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 20px;
}

.nav-button {
    padding: 10px 20px;
    background: #ae1c3f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    min-width: 120px;
    transition: all 0.2s ease;
}

.nav-button:hover:not(:disabled) {
    background: #8f1734;
}

.nav-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #333;
    padding: 10px 20px;
    display: flex; /* Hiển thị các phần tử trên cùng một dòng */
    justify-content: space-between; /* Phân bố khoảng cách đều giữa các nút */
    align-items: center;
    z-index: 100;
}

.footer-container button {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    min-width: 120px;
    margin: 5px; /* Đảm bảo có một khoảng cách giữa các nút */
    color: white;
    background: #28a745;

}

.footer-container .toggle-button {
   background: #28a745;

}

.footer-container .submit-btn {
    background-color: #d32f2f; /* Nút "Nộp bài" */
}

.footer-container button:hover {
    opacity: 0.8; /* Hiệu ứng hover cho nút */
}





.question-list-toggle:hover {
    background: #f0f0f0;
}

.submit-button {
    background: #28a745;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.submit-button:hover {
    background: #218838;
}


/* Danh sách câu hỏi */
.question-list {
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 70px;
}

.question-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr) !important; /* 10 cột */
    gap: 2px !important; /* Khoảng cách nhỏ hơn giữa các nút */
    justify-items: center;
}

.grid-item {
    width: 25px !important;
    height: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 30%;
    font-size: 9px !important;
    font-weight: lighter !important;
    cursor: pointer;
    border: 1px solid #ddd;
    transition: all 0.2s ease;
}

.grid-item.active {
    background: #ae1c3f;
    color: white;
}

.grid-item.answered {
    background: #28a745;
    color: white;
}

.grid-item:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Mobile responsive */
@media (max-width: 768px) {
    .question-grid {
        grid-template-columns: repeat(5, 1fr);
        gap: 8px;
    }

    .grid-item {
        width: 30px;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
    }

    .exam-footer {
        flex-direction: column;
        gap: 10px;
    }
}

/* Modal overlay - nền mờ phía sau */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
}

/* Modal container */
.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    min-width: 350px;
    max-width: 90%;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: slideIn 0.3s ease-out;
}

/* Modal header */
.modal-content h2 {
    color: #2c3e50;
    margin: 0 0 1.5rem 0;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 2px solid #eee;
}

/* Kết quả container */
.result-info {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border: 1px solid #e9ecef;
}

/* Từng dòng kết quả */
.result-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;
    border-bottom: 1px dashed #dee2e6;
}

.result-item:last-child {
    border-bottom: none;
}

/* Label và giá trị */
.result-item .label {
    color: #6c757d;
    font-weight: 500;
}

.result-item .value {
    color: #2c3e50;
    font-weight: 600;
}

/* Điểm số đặc biệt */
.result-item .score {
    font-size: 1.5rem;
    color: #2ecc71;
}

/* Nút đóng */
.modal-content button {
    display: block;
    width: 100%;
    padding: 0.8rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.modal-content button:hover {
    background-color: #2980b9;
}

.modal-content button:active {
    transform: scale(0.98);
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Responsive */
@media (max-width: 480px) {
    .modal-content {
        padding: 1.5rem;
        min-width: 300px;
    }
    
    .modal-content h2 {
        font-size: 1.25rem;
    }
    
    .result-item {
        padding: 0.6rem 0;
    }
    
    .result-item .label {
        font-size: 0.9rem;
    }
    
    .result-item .value {
        font-size: 0.9rem;
    }
    
    .result-item .score {
        font-size: 1.25rem;
    }
}