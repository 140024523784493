/* frontend/src/styles/layout.css */

/* Header styles */
.header {
    background-color: #b60042;
    width: 100%;
    padding: 20px;
    text-align: center;
    color: white;
}

.header h1 {
    font-size: 1em;
    margin: 0;
}

