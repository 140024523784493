.exam-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f7fa;
}

.exam-container {
    flex: 1;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.exam-title {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
    font-size: 24px;
}

.exam-form {
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 500;
}

.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
}

.error-message {
    color: #dc3545;
    margin: 10px 0;
    padding: 10px;
    background-color: #ffe6e6;
    border-radius: 4px;
    font-size: 14px;
}

.exam-details {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 4px;
}

.exam-details h3 {
    color: #333;
    margin: 0 0 15px 0;
    font-size: 18px;
}

.exam-info {
    font-size: 15px;
    color: #444;
    line-height: 1.6;
}

.exam-structure {
    margin-top: 10px;
}

.exam-structure ul {
    list-style: none;
    padding-left: 0;
    margin-top: 8px;
}

.exam-structure li {
    padding: 4px 0;
    border-bottom: 1px dashed #ddd;
}

.start-exam-button {
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.start-exam-button:hover {
    background-color: #004494;
}

.start-exam-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .exam-container {
        padding: 15px;
    }
    
    .exam-form {
        padding: 15px;
    }
    
    .exam-title {
        font-size: 20px;
    }
}