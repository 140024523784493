.admin-dashboard-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f5f7fa;
}

.dashboard-container {
    flex: 1;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 15px;
    box-sizing: border-box;
}

.dashboard-title {
    font-size: 18px;
    color: #333;
    margin: 0 0 20px 0;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

/* Menu Container */
.admin-menu-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0;
    width: 100%;
}

/* Menu Items */
.admin-menu-item {
    display: block;
    text-decoration: none;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 15px;
    transition: transform 0.2s ease;
    width: 100%;
    box-sizing: border-box;
}

.admin-menu-content {
    width: 100%;
}

.admin-menu-content h3 {
    margin: 0;
    color: #ae1c3f;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

/* Header */
.site-header {
    background-color: #ae1c3f;
    padding: 15px;
    color: white;
    width: 100%;
    box-sizing: border-box;
}

.site-header h1 {
    margin: 0;
    font-size: 20px;
    text-align: center;
}

/* Footer */
.site-footer {
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .dashboard-container {
        padding: 10px;
    }

    .admin-menu-item {
        padding: 12px;
        margin: 0;
    }

    .admin-menu-content h3 {
        font-size: 15px;
        white-space: normal;
        line-height: 1.3;
    }
}

/* Fix iOS Safari padding issue */
@supports (-webkit-touch-callout: none) {
    .admin-dashboard-page {
        padding-bottom: env(safe-area-inset-bottom);
    }
}