/* Leaderboard.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
}

.leaderboard-container {
    max-width: 800px;
    margin: 50px auto; /* Căn giữa container */
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-top: 80px !important;
}

h2 {
    text-align: center;
    color: #ae1c3f;
    margin-bottom: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

table th, table td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

table th {
    background-color: #f4f4f4;
}

table tbody tr:hover {
    background-color: #f9f9f9;
}

/* Điều chỉnh cho các dòng lỗi */
.error-message {
    color: red;
    text-align: center;
    margin: 10px 0;
}

.back-to-dashboard {
    display: block;
    text-align: center;
    margin: 15px 0;
    color: #007bff;
    text-decoration: none;
}

.back-to-dashboard:hover {
    text-decoration: underline;
}

/* Điều chỉnh header và footer */
header, footer {
    background-color: #ae1c3f;
    color: white;
    text-align: center;
    padding: 10px 0;
}

footer {
  background-color: #333;
    color: white !important;
    text-align: center !important;
    padding: 5px 0;
    font-size: 10px !important;
    position: fixed; /* Sử dụng fixed để luôn giữ footer ở cuối màn hình */
    bottom: 0 !important; /* Gắn footer ở đáy màn hình */
    left: 0;
    width: 100%;
}

/* Căn giữa biểu tượng huy chương */
.rank-icon {
    width: 24px; /* Hoặc kích thước bạn muốn */
    height: 24px;
    display: block;
    margin: 0 auto; /* Căn giữa icon */
}

/* Media Query cho thiết bị di động */
@media (max-width: 768px) {
    .leaderboard-container {
        padding: 15px;
        margin: 20px;
    }

    table th, table td {
        padding: 8px; /* Giảm padding để dễ dàng nhìn trên màn hình nhỏ */
    }

    h2 {
        font-size: 1.5em; /* Giảm kích thước tiêu đề trên di động */
    }
}
