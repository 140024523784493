/* manage-questions.css */
.manage-questions-page {
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 2rem;
}

.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
}

.dashboard-title {
    color: #2c3e50;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.back-to-dashboard {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #6c757d;
    font-size: 0.95rem;
    margin-bottom: 2rem;
    transition: color 0.2s ease;
}

.back-to-dashboard:hover {
    color: #343a40;
}

/* Search và Action Bar */
.action-bar {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;
}

.search-input {
    flex: 1;
    padding: 0.75rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.search-input:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.btn-add-new, .btn-import, .btn-export {
    background-color: #4299e1;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.btn-add-new:hover, .btn-import:hover, .btn-export:hover {
    background-color: #3182ce;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11);
}

.btn-export {
    background-color: #48bb78;
}

.btn-export:hover {
    background-color: #38a169;
}

.btn-import {
    background-color: #ed8936;
}

.btn-import:hover {
    background-color: #dd6b20;
}

/* Table Styles */
.table-container {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
    margin-bottom: 2rem;
}

.questions-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
}

.questions-table th {
    background-color: #f8fafc;
    color: #4a5568;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 1rem;
    border-bottom: 2px solid #e2e8f0;
    text-align: center;
}

.questions-table td {
    padding: 1rem;
    color: #2d3748;
    font-size: 0.95rem;
    border-bottom: 1px solid #e2e8f0;
    vertical-align: middle;
}

/* Định nghĩa chiều rộng và style cho từng cột */
.questions-table th:nth-child(1), /* Mã */
.questions-table td:nth-child(1) {
    width: 10%;
    white-space: nowrap;
    text-align: center;
}

.questions-table th:nth-child(2), /* Câu hỏi */
.questions-table td:nth-child(2) {
    width: 45%;
    white-space: normal;
    word-wrap: break-word;
    text-align: left;
}

.question-content {
    display: -webkit-box;
    
    overflow: hidden;
    line-height: 1.5;
}

.questions-table th:nth-child(3), /* Đáp án đúng */
.questions-table td:nth-child(3) {
    width: 10%;
    text-align: center;
    white-space: nowrap;
}

.questions-table th:nth-child(4), /* Chuyên mục */
.questions-table td:nth-child(4) {
    width: 15%;
    text-align: center;
    white-space: nowrap;
}

.questions-table th:nth-child(5), /* Chức năng */
.questions-table td:nth-child(5) {
    width: 20%;
    text-align: center;
}

.questions-table tbody tr {
    transition: all 0.2s ease;
}

.questions-table tbody tr:hover {
    background-color: #f8fafc;
    transform: translateY(-1px);
}

/* Table Action Buttons */
.table-actions {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

.table-actions button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 70px;
}

.table-actions .btn-edit {
    background-color: #4299e1;
    color: white;
}

.table-actions .btn-edit:hover {
    background-color: #3182ce;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(66, 153, 225, 0.3);
}

.table-actions .btn-delete {
    background-color: #f56565;
    color: white;
}

.table-actions .btn-delete:hover {
    background-color: #e53e3e;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(245, 101, 101, 0.3);
}

/* Thanh cuộn tùy chỉnh */
.table-container::-webkit-scrollbar {
    height: 8px;
}

.table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background-color: white;
    border-radius: 12px;
    padding: 2rem;
    width: 100%;
    max-width: 700px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    max-height: 90vh;
    overflow-y: auto;
}

.modal h3 {
    color: #2d3748;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

/* Form Styles */
.input-field,
.textarea-field,
.modal select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    margin-bottom: 1rem;
}

.textarea-field {
    min-height: 100px;
    resize: vertical;
}

.input-field:focus,
.textarea-field:focus,
.modal select:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.answers-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.answer-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #f8fafc;
    padding: 0.75rem;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
}

.shuffle-options {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
    background-color: #f8fafc;
    padding: 0.75rem;
    border-radius: 8px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.btn-primary, 
.btn-secondary {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.btn-primary {
    background-color: #4299e1;
    color: white;
}

.btn-primary:hover {
    background-color: #3182ce;
}

.btn-secondary {
    background-color: #e2e8f0;
    color: #4a5568;
}

.btn-secondary:hover {
    background-color: #cbd5e0;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
}

.pagination button {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background-color: white;
    color: #4a5568;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.pagination button:hover {
    background-color: #f8fafc;
}

.pagination button.active {
    background-color: #4299e1;
    color: white;
    border-color: #4299e1;
}

.pagination span {
    color: #718096;
    font-size: 0.95rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .manage-questions-page {
        padding: 1rem;
    }

    .action-bar {
        flex-direction: column;
    }

    .search-input,
    .btn-add-new,
    .btn-import,
    .btn-export {
        width: 100%;
    }

    .answers-group {
        grid-template-columns: 1fr;
    }

    .table-actions {
        flex-wrap: wrap;
    }

    .modal {
        margin: 1rem;
        padding: 1.5rem;
    }
}
/* Thêm vào file manage-categories.css */
footer {
    display: none !important;
}

/* Hoặc cụ thể hơn */
.manage-categories-page footer {
    display: none !important;
}