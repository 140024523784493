.login-page {
    background-color: #f8f9fa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.login-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 400px; /* Giới hạn chiều rộng tối đa */
    text-align: center;
    border-top: 5px solid #ae1c3f;
}

.admin-logo {
    width: 60px; /* Điều chỉnh kích thước nhỏ hơn cho logo */
    height: 60px;
    margin-bottom: 15px;
}

.login-title {
    font-size: 20px; /* Giảm font-size cho tiêu đề trên thiết bị nhỏ */
    color: #ae1c3f;
    margin-bottom: 10px;
}

.input-field {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
}

.submit-button {
    background-color: #ae1c3f;
    color: white;
    border: none;
    padding: 12px 18px;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #9a1636;
}

.message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

/* Media Query để tối ưu giao diện trên màn hình nhỏ */
@media (max-width: 480px) {
    .login-container {
        padding: 20px; /* Giảm padding để phù hợp hơn trên thiết bị nhỏ */
        max-width: 90%; /* Đảm bảo container không vượt quá chiều rộng màn hình */
    }

    .login-title {
        font-size: 18px; /* Điều chỉnh font-size để hiển thị tốt trên màn hình nhỏ */
    }

    .admin-logo {
        width: 50px; /* Giảm kích thước logo */
        height: 50px;
    }

    .submit-button {
        font-size: 14px; /* Giảm kích thước chữ của nút */
        padding: 10px 16px;
    }

    .input-field {
        font-size: 13px; /* Giảm font-size của input trên màn hình nhỏ */
    }
}
