/* author.css */

.author-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f4f4f4;
    min-height: 100vh;
}

.author-container {
    margin: 0 auto;
    max-width: 800px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

.author-container h2 {
    font-size: 28px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.author-content {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    text-align: center;
}

.author-content p {
    margin-bottom: 10px;
}

.back-to-dashboard {
    display: block;
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    text-decoration: none;
    color: #007bff;
}

.back-to-dashboard:hover {
    color: #0056b3;
}

