/* Main Layout */
.manage-exam-configs-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: system-ui, -apple-system, sans-serif;
    background-color: #f5f7fa;
}

.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;
    width: 100%;
    flex: 1;
}

/* Header & Titles */
.dashboard-container h2 {
    font-size: 1.75rem;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    font-weight: 600;
    text-align: left;
}

/* Alert Messages */
.alert {
    padding: 12px 16px;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 0.9rem;
}

.alert-success {
    background-color: #d1fae5;
    color: #065f46;
    border: 1px solid #34d399;
}

.alert-error {
    background-color: #fee2e2;
    color: #991b1b;
    border: 1px solid #f87171;
}

/* Actions Bar */
.actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
}

.btn-primary {
    background-color: #2563eb;
    color: white;
    padding: 10px 20px;
    border-radius: 6px;
    border: none;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-primary:hover {
    background-color: #1d4ed8;
    transform: translateY(-1px);
}

/* Table Styles */
.configs-list {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    overflow: hidden;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    background-color: #f8fafc;
    color: #475569;
    font-weight: 600;
    font-size: 0.875rem;
    text-align: left;
    padding: 12px 16px;
    border-bottom: 1px solid #e2e8f0;
}

td {
    padding: 12px 16px;
    color: #1e293b;
    font-size: 0.875rem;
    border-bottom: 1px solid #e2e8f0;
}

tbody tr:hover {
    background-color: #f8fafc;
}

/* Config Details List */
td ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

td li {
    padding: 4px 0;
    color: #475569;
}

td li.total {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px dashed #e2e8f0;
    font-weight: 500;
    color: #1e293b;
}

/* Action Buttons */
.btn {
    padding: 6px 12px;
    border-radius: 6px;
    border: none;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-edit {
    background-color: #0ea5e9;
    color: white;
    margin-right: 8px;
}

.btn-edit:hover {
    background-color: #0284c7;
}

.btn-delete {
    background-color: #ef4444;
    color: white;
}

.btn-delete:hover {
    background-color: #dc2626;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

.modal {
    background: white;
    border-radius: 12px;
    padding: 24px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
}

.modal h3 {
    color: #1e293b;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.modal h4 {
    color: #475569;
    font-size: 1rem;
    font-weight: 500;
    margin: 1.5rem 0 1rem;
}

/* Form Groups */
.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    color: #475569;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #2563eb;
    outline: none;
    box-shadow: 0 0 0 3px rgba(37,99,235,0.1);
}

/* Detail Items */
.detail-item {
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 12px;
}

.detail-inputs {
    display: grid;
    grid-template-columns: 2fr 1fr auto;
    gap: 12px;
    align-items: center;
}

.detail-inputs select,
.detail-inputs input {
    padding: 8px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
}

.percentage {
    color: #6b7280;
    font-size: 0.875rem;
}

.detail-info {
    margin-top: 8px;
    font-size: 0.875rem;
    color: #6b7280;
}

.total-questions {
    margin-top: 16px;
    padding: 12px;
    background-color: #f1f5f9;
    border-radius: 6px;
    font-weight: 500;
    color: #1e293b;
    text-align: right;
}

/* Modal Actions */
.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid #e2e8f0;
}

.btn-secondary {
    background-color: #e2e8f0;
    color: #475569;
}

.btn-secondary:hover {
    background-color: #cbd5e1;
}

/* Empty State */
.text-center {
    text-align: center;
    color: #6b7280;
    padding: 24px;
    font-style: italic;
}