/* Main Layout */
.manage-exam-configs-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: system-ui, -apple-system, sans-serif;
    background-color: #fff;
}

.dashboard-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

/* Page Header */
.dashboard-container h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

/* Table Header Row */
.configs-list {
    margin-top: 20px;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    background: white;
}

th {
    background-color: #f8f9fa;
    padding: 12px;
    font-weight: 500;
    color: #333;
    font-size: 14px;
    text-align: left;
    border-bottom: 2px solid #dee2e6;
}

td {
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    font-size: 14px;
    color: #333;
}

/* Add New Button */
.btn-add-new {
    background-color: #ae1c3f;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    font-size: 14px;
}

.btn-add-new:hover {
    background-color: #8f1734;
}

/* Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    position: relative;
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    padding: 24px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 90vh;
    overflow-y: auto;
}

.modal h3 {
    margin: 0 0 20px 0;
    font-size: 18px;
    color: #333;
    text-align: center;
    font-weight: 500;
}

.modal form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* Form Elements in Modal */
.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-size: 14px;
}

input[type="text"],
textarea,
select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

textarea {
    min-height: 100px;
    resize: vertical;
}

/* Config Detail Row */
.detail-item {
    margin-bottom: 15px;
}

.detail-inputs {
    display: flex;
    gap: 10px;
    align-items: center;
}

.detail-inputs select {
    flex: 2;
}

.detail-inputs input {
    flex: 1;
    width: 80px;
}

.detail-inputs button {
    padding: 8px;
    background: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Total Questions Display */
.total-questions {
    margin-top: 15px;
    padding: 10px;
    background: #f8f9fa;
    text-align: right;
    border-radius: 4px;
    font-size: 14px;
}

/* Add Category Button */
.btn-add {
    width: 100%;
    padding: 8px;
    background: #f8f9fa;
    border: 1px dashed #ddd;
    color: #666;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
}

.btn-add:hover {
    background: #eee;
}

/* Modal Actions */
.modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.modal-actions button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    border: none;
}

.btn-primary {
    background: #ae1c3f;
    color: white;
}

.btn-secondary {
    background: #6c757d;
    color: white;
}

/* Table Actions */
.table-actions {
    display: flex;
    gap: 8px;
}

.btn-edit,
.btn-delete {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    color: white;
}

.btn-edit {
    background: #ffc107;
}

.btn-delete {
    background: #dc3545;
}

/* Alert Messages */
.alert {
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 14px;
}

.alert-success {
    background: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.alert-error {
    background: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/* Category Item Display */
.category-item {
    background: #f8f9fa;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Footer */
footer {
    margin-top: auto;
    padding: 15px;
    background: #333;
    color: white;
    text-align: center;
    font-size: 14px;
}