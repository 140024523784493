/* Container styles */
.study-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
}

.study-main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 1rem;
}

.study-content {
    width: 100%;
    max-width: 400px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    position: relative;
}

/* Back link styles */
.study-back-link {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    color: #666;
    text-decoration: none;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
}

.study-back-link:hover {
    color: #ae1c3f;
}

/* Heading styles */
.study-heading {
    color: #ae1c3f;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0 0 2rem 0;
}

/* Form group styles */
.study-input-group {
    margin-bottom: 1.5rem;
}

.study-label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: 500;
    font-size: 0.95rem;
}

/* Select and input styles */
.study-select,
.study-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    background-color: white;
    transition: all 0.3s ease;
    box-sizing: border-box;
}

/* Remove number input arrows */
.study-input::-webkit-outer-spin-button,
.study-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.study-input[type="number"] {
    -moz-appearance: textfield;
}

/* Focus states */
.study-select:focus,
.study-input:focus {
    outline: none;
    border-color: #ae1c3f;
    box-shadow: 0 0 0 2px rgba(174, 28, 63, 0.1);
}

/* Error message styles */
.study-error {
    color: #dc3545;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    text-align: center;
    padding: 0.5rem;
    background-color: rgba(220, 53, 69, 0.1);
    border-radius: 4px;
}

/* Submit button styles */
.study-submit {
    width: 100%;
    padding: 0.875rem;
    background: #ae1c3f;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.study-submit:hover {
    background: #8e1733;
    transform: translateY(-1px);
}

.study-submit:disabled {
    background: #ccc;
    cursor: not-allowed;
    transform: none;
}

/* Responsive styles */
@media (max-width: 768px) {
    .study-main {
        padding: 1rem;
    }

    .study-content {
        padding: 1.5rem;
        margin-top: 80px;
    }

    .study-heading {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .study-back-link {
        top: 1rem;
        left: 1rem;
    }

    .study-select,
    .study-input {
        padding: 0.625rem;
        font-size: 0.9375rem;
    }

    .study-input-group {
        margin-bottom: 1rem;
    }
}