/* frontend/src/styles/home.css */

.home-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Chiều cao tối thiểu của trang để chiếm hết không gian */
    background-color: #f9f9f9; /* Nền trắng */
}

.home-header {
    background-color: rgb(174, 28, 63); /* Màu đỏ booc đô */
    color: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-header h2 {
    margin: 0;
    font-size: 18px; /* Hoặc giá trị lớn hơn nếu bạn có */
}

.logout-button {
    background-color: #ff7043; /* Màu cam đậm hơn để nổi bật */
    color: #fff;
    border: 1px solid #e64a19; /* Viền cam đậm hơn */
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.logout-button:hover {
    background-color: #d84315; /* Tông đậm hơn cho hover */
    border-color: #bf360c;
}

.menu-container {
    flex: 1; /* Cho phép phần này chiếm không gian còn lại */
    display: flex;
    margin-bottom: 150px; /* Giảm khoảng cách giữa menu và footer */
    flex-direction: column;
    padding: 5px;
    gap: 5px;
    justify-content: center;
}

.menu-item {
    margin: 10px auto; /* Giảm margin top và bottom để mục gần nhau hơn */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 250px;
    max-height: 250px;
    text-align: center;
    text-decoration: none;
    color: #333; 
    background-color: #fff; 
    padding: 5px;
    border-radius: 12px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); 
    transition: transform 0.2s, box-shadow 0.2s;
}

.menu-item:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3); /* Đổ bóng đậm hơn khi hover */
}

.menu-item img {
    width: 35px;
    height: 35px;
    margin-bottom: 0px;
}

.menu-item h3 {
    margin: 10px 0 5px;
    font-size: 20px;
    color: rgb(174, 28, 63); /* Màu đỏ booc đô cho tiêu đề menu */
}

.menu-item p {
    margin: 0;
    font-size: 14px;
    color: #666; /* Màu chữ xám nhạt */
}

.home-footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    font-size: 10px;
    position: relative; /* Đảm bảo footer không bị dính lên */
    width: 100%;
    height: 100%;
}

/* Responsive để footer luôn ở cuối trên mobile */
@media (max-width: 768px) {
    .home-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh; /* Đảm bảo trang luôn chiếm hết chiều cao */
    }

    .home-footer {
        position: relative; /* Đảm bảo footer không bị dính lên khi cuộn */
    }
}
