/* Quiz Page Layout */
.quiz-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
}

.quiz-content {
    flex: 1;
    padding: 2rem 1rem;
    padding-bottom: 120px;
    overflow-y: auto;
}

/* Question Styles */
.quiz-question__container {
    max-width: 800px;
    margin-top: 80px !important;
    margin: 0 auto;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
}

.quiz-question__header {
    margin-bottom: 1.5rem;
}

.quiz-question__number {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 0.5rem;
}

.quiz-question__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #333;
    text-align: justify;            /* Căn đều 2 bên */
    text-justify: inter-word;       /* Căn đều khoảng cách giữa các từ */
    padding: 0 10px;                /* Thêm padding 2 bên để text không sát viền */
    margin-bottom: 15px;            /* Thêm khoảng cách với phần options */
    word-wrap: break-word;          /* Đảm bảo text wrap đúng cách */
    hyphens: auto;                  /* Tự động gạch nối khi cần thiết */
    -webkit-hyphens: auto;          /* Hỗ trợ Safari */
    -ms-hyphens: auto;
}

/* Options Styles */
.quiz-options__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #333;
    text-align: justify;
    text-justify: inter-word;
    padding: 0 10px;
    margin-bottom: 15px;
    word-wrap: break-word;
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
}

.quiz-option__item {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: white;
}

.quiz-option__item:hover:not(.quiz-option--correct):not(.quiz-option--incorrect) {
    border-color: #ae1c3f;
    transform: translateY(-1px);
}

.quiz-option--correct {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.quiz-option--incorrect {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}

/* Navigation Styles */
.quiz-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.quiz-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    min-width: 120px;
    transition: all 0.2s ease;
}

.quiz-button--nav {
    background-color: #ae1c3f;
    color: white;
}

.quiz-button--nav:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.quiz-button--submit {
    background-color: #ae1c3f;
    color: white;
}

.quiz-button--retry,
.quiz-button--home {
    background-color: #ae1c3f;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    min-width: 120px;
    transition: all 0.2s ease;
}

.quiz-button:hover:not(:disabled) {
    background-color: #8e1733;
}

/* Result Styles */
.quiz-result__container {
    max-width: 800px;
    margin: 0 auto;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 80px; /* Thêm margin để tránh bị che bởi quiz-actions */
}

.quiz-result__header {
    text-align: center;
    margin-bottom: 2rem;
}

.quiz-result__title {
    color: #ae1c3f;
    margin-bottom: 1rem;
}

.quiz-result__summary {
    font-size: 1.1rem;
    color: #666;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.quiz-review__item {
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.quiz-review__header {
    margin-bottom: 1rem;
}

.quiz-review__number {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.quiz-status--correct {
    color: #155724;
    background-color: #d4edda;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.quiz-status--incorrect {
    color: #721c24;
    background-color: #f8d7da;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.quiz-explanation {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    color: #666;
}

.quiz-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .quiz-content {
        padding: 1rem;
        padding-bottom: 100px;
    }

    .quiz-question__container {
        margin-top: 60px !important;
    }

    .quiz-question__container,
    .quiz-result__container {
        padding: 1rem;
    }

    .quiz-button {
        min-width: 100px;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }

    .quiz-navigation {
        gap: 0.5rem;
        padding: 0.75rem;
    }
    .quiz-actions {
        padding: 0.75rem;
    }

    .quiz-button--retry,
    .quiz-button--home {
        min-width: 100px;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }
}