.exam-history-container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.exam-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.back-button {
    color: #666;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 6px;
    transition: all 0.3s;
}

.back-button:hover {
    background: #f0f0f0;
}

.exam-analysis-panel {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.analysis-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-bottom: 15px;
}

.stat-item {
    text-align: center;
    padding: 10px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.stat-value {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    color: #ae1c3f;
}

.stat-label {
    font-size: 0.9rem;
    color: #666;
}

.quick-insights {
    background: white;
    border-radius: 8px;
    padding: 15px;
}

.insight {
    margin: 8px 0;
    padding-left: 20px;
    position: relative;
}

.history-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.history-table th,
.history-table td {
    padding: 12px;
    border: 1px solid #eee;
    text-align: left;
}

.history-table th {
    background: #f8f9fa;
    font-weight: 500;
}

.history-table tr:hover {
    background: #f8f9fa;
}

.score-percent {
    margin-left: 5px;
    color: #666;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .exam-history-container {
        margin: 10px;
        padding: 15px;
    }

    .analysis-stats {
        grid-template-columns: 1fr;
    }

    .history-table {
        display: block;
        overflow-x: auto;
    }

    .stat-value {
        font-size: 1.2rem;
    }
}