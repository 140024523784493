/* Toàn trang */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    background: linear-gradient(135deg, #fdf2f8, #ffe8e9);
    background-size: cover;
}

/* Overlay để thêm hiệu ứng mờ */
.abstract-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(253, 242, 248, 0.8), rgba(255, 232, 233, 0.8));
    backdrop-filter: blur(8px); /* Hiệu ứng mờ */
    z-index: -1;
}

/* Trang đăng nhập */
.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;  /* Căn giữa và đảm bảo footer luôn ở dưới cùng */
    min-height: 100vh;
    padding: 0 20px; /* Thêm padding trái và phải để không bị hẹp */
    box-sizing: border-box;
    position: relative;
}

/* Cố định header */
.header {
    background-color: rgba(182, 0, 66, 0.9);
    color: white;
    padding: 15px;
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

/* Nội dung chính */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 350px;
    width: 100%;
    padding: 20px;
    margin-top: 50px;  /* Thêm khoảng cách cho content */
    position: relative;
}

.login-logo {
    width: 70px;
    margin: 20px 0;
}

.login-box {
    background-color: rgba(255, 255, 255, 0.9); /* Làm nền nhẹ hơn */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 10px;
}

/* Tab chuyển đổi Đăng Nhập / Đăng Ký */
.tab-switch {
    display: flex;
    margin-bottom: 15px;
}

.tab-switch button {
    flex: 1;
    padding: 10px;
    font-size: 1em;
    border: none;
    background-color: #f1f1f1;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    border-radius: 4px 4px 0 0;
    transition: background-color 0.3s ease;
}

.tab-switch .active-tab {
    background-color: #b60042;
    color: white;
}

.input-field {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
}

.input-field:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Nút gửi (Đăng Nhập / Đăng Ký) */
.submit-button, .guest-button {
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    font-size: 1em;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.submit-button {
    background-color: #007bff;
}

.submit-button:hover {
    background-color: #0056b3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.guest-button {
    background-color: #6c757d;
    margin-top: 10px;
}

.guest-button:hover {
    background-color: #5a6268;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.footer {
    background-color: #333 !important;
    color: white !important;
    text-align: center !important;
    padding: 5px 0 !important;
    font-size: 101px !important;
    position: fixed !important;
    bottom: 0 !important;
    width: 100% !important;
    left: 0 !important;
    max-width: 100% !important;
    z-index: 9999 !important; /* Đảm bảo footer luôn nằm trên */
}



.logo .letter {
  font-weight: bold; /* In đậm */
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1); /* Bóng chữ */
  padding: 3px;
  font-size: 30px;

}

.logo .letter:hover {
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5); /* Tăng bóng chữ khi hover */
  transform: scale(1.1); /* Phóng to chữ khi hover */
  transition: all 0.3s ease-in-out;
}
/* Thêm vào login.css */
.guest-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
}

.guest-button:hover {
    background-color: #5a6268;
}

/* Thêm vào home.css */
.menu-item.disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: #f8f9fa;
    position: relative;
}

.guest-notice {
    color: #dc3545;
    font-size: 0.8rem;
    margin-top: 8px;
    text-align: center;
    background-color: rgba(220, 53, 69, 0.1);
    padding: 4px;
    border-radius: 4px;
}

.menu-item.disabled:hover {
    transform: none;
    box-shadow: none;
}

.menu-item.disabled .menu-content {
    pointer-events: none;
}